
export default function Calendar(){

    return (
        <svg
  version="1.1"
  id="Layer_2"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 24 24"
  style={{ enableBackground: "new 0 0 24 24" }}
  xmlSpace="preserve"
>
  <style
    type="text/css"
    dangerouslySetInnerHTML={{
      __html:
        "\n\t.st0{display:none;}\n\t.st1{display:inline;}\n\t.st2{display:inline;fill:#E5E5E5;}\n\t.st3{display:inline;fill:#FFFFFF;}\n\t.st4{fill:#E5E5E5;}\n"
    }}
  />
  <g id="Layer_3" className="st0">
    <rect className="st1" width={24} height={24} />
  </g>
  <g id="_x23_262626ff" className="st0"></g>
  <g id="_x23_ffffffff" className="st0">
    <path
      className="st2"
      d="M0.7,3.2C1.1,3,1.6,3,2.1,3c6.7,0,13.4,0,20.1,0C23.1,2.9,24,3.6,24,4.5c0,0.9-0.9,1.6-1.8,1.5
		C15.5,6,8.8,6,2.1,6C1.5,6,0.7,6,0.3,5.4C-0.3,4.7,0,3.6,0.7,3.2z"
    />
    <path
      className="st2"
      d="M0.8,10.7c0.4-0.2,0.9-0.2,1.3-0.2c6.7,0,13.4,0,20.1,0c0.9-0.1,1.8,0.6,1.8,1.5c0,0.9-0.9,1.6-1.8,1.5
		c-6.7,0-13.4,0-20.1,0c-0.6,0-1.4,0-1.8-0.6C-0.3,12.2,0,11.1,0.8,10.7z"
    />
    <path
      className="st2"
      d="M0.8,18.2C1.2,18,1.6,18,2.1,18c6.7,0,13.4,0,20.1,0c0.7-0.1,1.4,0.3,1.7,0.9c0.5,0.9-0.3,2.1-1.3,2.1
		c-6.8,0-13.6,0-20.4,0c-0.6,0-1.4,0-1.8-0.6C-0.3,19.7,0,18.6,0.8,18.2z"
    />
  </g>
  <g id="_x23_e5e5e5ff" className="st0">
    <path
      className="st2"
      d="M10.3,0.2c1.6-0.4,3.4-0.2,4.8,0.5c2.2,1.1,3.8,3.4,3.9,5.9c0.2,2.4-1,4.7-2.9,6.1c4.4,1.5,7.6,5.8,7.9,10.4
		c0,0.7-0.9,1.3-1.5,0.9c-0.5-0.3-0.5-0.9-0.6-1.4c-0.4-2.7-1.9-5.1-4.1-6.6c-2.4-1.8-5.6-2.2-8.4-1.5c-3.3,0.8-6,3.5-7,6.7
		c-0.3,0.8-0.2,1.8-0.6,2.5C1.3,24.4-0.1,23.9,0,23c0.3-4.6,3.6-8.8,7.9-10.3c-1.3-0.9-2.2-2.3-2.6-3.8C4.8,7,5,4.9,6.1,3.3
		C7,1.8,8.5,0.6,10.3,0.2 M10.9,2.1C9.3,2.4,8,3.6,7.4,5C6.8,6.5,6.9,8.2,7.7,9.5c0.8,1.3,2.1,2.3,3.7,2.4c1.6,0.2,3.2-0.4,4.3-1.5
		c1.2-1.2,1.6-3.2,1.2-4.8C16.1,3.1,13.4,1.5,10.9,2.1z"
    />
  </g>
  <g
    id="_x23_e5e5e5ff_00000059309478266267941320000001716134725718594979_"
    className="st0"
  >
    <path
      className="st2"
      d="M9.9,0.2c2.9-0.5,5.9,0,8.4,1.6c2.5,1.6,4.5,4,5.3,6.8c0.8,3.1,0.6,6.5-1,9.3c-1.4,2.4-3.6,4.4-6.3,5.4
		c-2.7,1-5.8,1-8.5,0c-2.6-1-4.8-3-6.3-5.4c-1.4-2.5-1.8-5.5-1.3-8.3C1.1,4.9,5.2,1,9.9,0.2 M8.7,6.9C9.4,7,10.2,7,11,7
		c0-1.5,0-3.1,0-4.6C9.9,3.6,9.1,5.2,8.7,6.9 M13,2.4c0,1.5,0,3.1,0,4.6c0.8,0,1.6,0,2.4-0.1C14.9,5.3,14.2,3.6,13,2.4 M3.9,6.2
		c0.9,0.2,1.8,0.4,2.8,0.5C7,5.3,7.5,4,8.3,2.7C6.5,3.4,5,4.7,3.9,6.2 M15.8,2.7c0.7,1.2,1.2,2.6,1.6,4c0.9-0.1,1.9-0.3,2.8-0.5
		C19,4.7,17.5,3.4,15.8,2.7 M2.8,8c-0.4,1-0.6,2-0.8,3c1.3,0,2.7,0,4,0c0-0.8,0.1-1.6,0.2-2.3C5.1,8.5,4,8.3,2.8,8 M17.8,8.7
		C17.9,9.4,18,10.2,18,11c1.3,0,2.7,0,4,0c-0.1-1-0.4-2.1-0.8-3C20.1,8.3,18.9,8.5,17.8,8.7 M8.2,8.9C8.2,9.6,8.1,10.3,8,11
		c1,0,2,0,3,0c0-0.7,0-1.4,0-2.1C10.1,9,9.2,9,8.2,8.9 M13,9c0,0.7,0,1.4,0,2.1c1,0,2,0,3,0c0-0.7-0.1-1.4-0.2-2.1
		C14.9,9,13.9,9,13,9 M2.1,13c0.1,1,0.4,2.1,0.8,3c1.1-0.3,2.3-0.5,3.4-0.7C6.2,14.6,6.1,13.8,6,13C4.7,13,3.4,13,2.1,13 M8,13
		c0,0.7,0.1,1.4,0.2,2.1c0.9-0.1,1.8-0.1,2.8-0.1c0-0.7,0-1.4,0-2C10,13,9,13,8,13 M13,13c0,0.7,0,1.4,0,2c0.9,0,1.9,0,2.8,0.1
		c0.1-0.7,0.2-1.4,0.2-2.1C15,13,14,13,13,13 M18,13c0,0.8-0.1,1.6-0.2,2.3c1.2,0.2,2.3,0.4,3.4,0.7c0.4-1,0.7-2,0.8-3
		C20.7,13,19.3,13,18,13 M8.7,17.1c0.5,1.7,1.1,3.3,2.3,4.6c0-1.5,0-3.1,0-4.6C10.2,17.1,9.5,17.1,8.7,17.1 M13,17
		c0,1.5,0,3.1,0,4.6c1.2-1.3,1.9-2.9,2.3-4.6C14.6,17.1,13.8,17.1,13,17 M3.9,17.8c1.1,1.5,2.6,2.7,4.4,3.5c-0.8-1.2-1.2-2.6-1.6-4
		C5.7,17.4,4.8,17.6,3.9,17.8 M15.8,21.3c1.7-0.7,3.2-2,4.4-3.5c-0.9-0.2-1.8-0.4-2.8-0.5C17,18.7,16.5,20.1,15.8,21.3z"
    />
  </g>
  <g
    id="_x23_e5e5e5ff_00000183250023799203021590000013081205298951287726_"
    className="st0"
  >
    <path
      className="st2"
      d="M11.5,0.1c0.3-0.2,0.7-0.1,1,0c3.5,1.3,7,2.6,10.5,3.8c0.3,0.1,0.8,0.3,0.9,0.7c0.2,0.5-0.1,1.2-0.7,1.4
		C22.8,6,22.4,6,22,6c0,0.8,0.1,1.6-0.1,2.4C21.6,9.1,20.7,9,20,9c0,2.3,0,4.7,0,7c0.7,0,1.6-0.1,1.9,0.6c0.2,0.8,0,1.6,0.1,2.4
		c0.7,0,1.6-0.1,1.9,0.6c0.2,0.8,0,1.7,0.1,2.6c0,0.5,0.1,1.1-0.3,1.5c-0.2,0.3-0.6,0.3-0.9,0.3c-7.2,0-14.4,0-21.6,0
		C0.7,24,0.1,23.7,0,23.2c-0.1-0.9,0-1.7,0-2.6c0-0.4-0.1-1,0.3-1.3C0.8,18.8,1.4,19,2,19c0-0.8-0.1-1.6,0.1-2.4
		C2.4,15.8,3.3,16,4,16c0-2.3,0-4.7,0-7C3.3,9,2.4,9.1,2.1,8.4C1.9,7.6,2,6.8,2,6C1.6,6,1.2,6,0.8,6c-0.6-0.1-1-0.9-0.7-1.4
		C0.3,4.2,0.6,4.1,1,3.9C4.5,2.7,8,1.4,11.5,0.1 M6.7,4c3.5,0.1,7,0,10.6,0c-1.8-0.6-3.5-1.3-5.3-1.9C10.2,2.7,8.5,3.4,6.7,4 M4,6
		c0,0.3,0,0.7,0,1c5.3,0,10.7,0,16,0c0-0.3,0-0.7,0-1C14.7,6,9.3,6,4,6 M6,9c0,2.3,0,4.7,0,7c0.3,0,0.7,0,1,0c0-2.3,0-4.7,0-7
		C6.7,9,6.3,9,6,9 M9,9c0,2.3,0,4.7,0,7c0.7,0,1.3,0,2,0c0-2.3,0-4.7,0-7C10.3,9,9.7,9,9,9 M13,9c0,2.3,0,4.7,0,7c0.7,0,1.3,0,2,0
		c0-2.3,0-4.7,0-7C14.3,9,13.7,9,13,9 M17,9c0,2.3,0,4.7,0,7c0.3,0,0.7,0,1,0c0-2.3,0-4.7,0-7C17.7,9,17.3,9,17,9 M4,18
		c0,0.3,0,0.7,0,1c5.3,0,10.7,0,16,0c0-0.3,0-0.7,0-1C14.7,18,9.3,18,4,18 M2,21c0,0.3,0,0.7,0,1c6.7,0,13.3,0,20,0c0-0.3,0-0.7,0-1
		C15.3,21,8.7,21,2,21z"
    />
  </g>
  <g
    id="_x23_ffffffff_00000103987296018067770790000004652659034538069156_"
    className="st0"
  >
    <path
      className="st3"
      d="M2,1.2C1.9,0.5,2.5-0.1,3.2,0c6,0,11.9,0,17.9,0c0.8,0,1.2,1,0.8,1.5C21.4,2.1,20.6,2,20,2c0,0.7,0,1.3,0,2
		c0.6,0.1,1.3-0.2,1.7,0.3C22,4.6,22,5,22,5.4c0,5.8,0,11.6,0,17.4c0.1,0.7-0.5,1.2-1.2,1.2c-5.9,0-11.8,0-17.6,0
		C2.6,24,2,23.6,2,23C2,15.7,2,8.5,2,1.2 M4,2c0,0.7,0,1.3,0,2c4.7,0,9.3,0,14,0c0-0.7,0-1.3,0-2C13.3,2,8.7,2,4,2 M4,6
		c0,5.3,0,10.7,0,16c1,0,2,0,3,0c0-5.3,0-10.7,0-16C6,6,5,6,4,6 M9,6c0,5.3,0,10.7,0,16c3.7,0,7.3,0,11,0c0-5.3,0-10.7,0-16
		C16.3,6,12.7,6,9,6z"
    />
    <path
      className="st3"
      d="M11.5,10.2c0.3-0.2,0.7-0.1,1.1-0.2c1.5,0,3,0,4.4,0c0.5,0,1,0.5,1,1c0,1,0,2,0,3c0,0.5-0.4,1-1,1
		c-1.6,0-3.2,0-4.9,0c-0.6,0.1-1.2-0.4-1.2-1c0-0.9,0-1.9,0-2.8C11,10.8,11.1,10.4,11.5,10.2 M13,12c0,0.3,0,0.7,0,1c1,0,2,0,3,0
		c0-0.3,0-0.7,0-1C15,12,14,12,13,12z"
    />
  </g>
  <g id="_x23_e5e5e5ff_00000143618635339139717610000009705044459080775303_">
    <path
      className="st4"
      d="M3.3,0.3C3.7-0.1,4.3,0,4.8,0c1.5,0,3-0.1,4.5,0.1C10.1,0.3,10,1.3,10,2h4c0-0.6-0.1-1.3,0.3-1.7
		c0.4-0.4,1-0.3,1.5-0.3c1.5,0,3-0.1,4.5,0.1C21.1,0.3,21,1.3,21,2c0.8,0,1.6-0.1,2.3,0.1C23.8,2.3,24,2.7,24,3.2
		c0,6.5,0,13.1,0,19.6c0.1,0.7-0.5,1.2-1.2,1.2c-7.1,0-14.3,0-21.4,0c-0.5,0-1.2-0.1-1.3-0.7C0,22.8,0,22.3,0,21.8
		C0,15.6,0,9.4,0,3.2c0-0.5,0.2-0.9,0.6-1.1C1.4,2,2.2,2,3,2C3,1.4,2.9,0.8,3.3,0.3 M5,2c0,0.7,0,1.3,0,2h3V2C7,2,6,2,5,2 M16,2
		c0,0.7,0,1.3,0,2h3V2C18,2,17,2,16,2 M2,4c0,1.3,0,2.7,0,4h20c0-1.3,0-2.7,0-4c-0.3,0-0.7,0-1,0c0,0.7,0.1,1.8-0.8,2
		c-1.5,0.1-2.9,0-4.4,0c-0.5,0-1.1,0.1-1.5-0.3C13.8,5.3,14,4.6,14,4h-4c0,0.7,0.1,1.8-0.8,2C7.8,6.1,6.3,6,4.8,6
		C4.3,6,3.7,6.1,3.3,5.7C2.9,5.3,3,4.6,3,4C2.7,4,2.3,4,2,4 M2,10v12h20V10H2z"
    />
    <path
      className="st4"
      d="M4.8,12C5.6,12,6.3,12,7,12c0.5,0,1,0.4,1,0.9c0,0.6,0.2,1.3-0.3,1.7C7.3,15.1,6.6,15,6,15
		c-0.6,0-1.3,0.1-1.7-0.3C3.9,14.2,4,13.5,4,13C4,12.5,4.4,12.1,4.8,12z"
    />
    <path
      className="st4"
      d="M10.8,12c0.7-0.1,1.4,0,2.2,0c0.5,0,1,0.4,1,1c0,0.6,0.2,1.3-0.3,1.7C13.2,15.1,12.6,15,12,15
		c-0.6,0-1.3,0.1-1.7-0.3c-0.4-0.4-0.3-1-0.3-1.5C9.9,12.7,10.3,12.1,10.8,12z"
    />
    <path
      className="st4"
      d="M16.8,12c0.7-0.1,1.5,0,2.2,0c0.5,0,1,0.4,1,1c0,0.6,0.2,1.3-0.3,1.7C19.2,15.2,18.5,15,18,15
		c-0.7,0-1.6,0.1-1.9-0.6C15.9,13.6,15.8,12.2,16.8,12z"
    />
    <path
      className="st4"
      d="M4.8,17C5.6,17,6.3,17,7,17c0.5,0,1,0.4,1,1c0,0.7,0.2,1.7-0.6,2C6.6,20.1,5.8,20,5,20c-0.6,0-1-0.6-1-1.2
		C3.9,18.1,4,17.1,4.8,17z"
    />
    <path
      className="st4"
      d="M10.8,17c0.8,0,1.7-0.1,2.5,0c0.8,0.3,0.7,1.3,0.7,2c0,0.5-0.5,1-1,0.9c-0.8,0-1.6,0.1-2.4-0.1
		c-0.7-0.3-0.7-1.1-0.6-1.8C9.9,17.6,10.3,17.1,10.8,17z"
    />
    <path
      className="st4"
      d="M16.8,17c0.7-0.1,1.5,0,2.2,0c0.5,0,1,0.4,1,1c0,0.7,0.2,1.6-0.6,1.9C18.6,20.1,17.8,20,17,20
		c-0.6,0-1-0.6-1-1.2C15.9,18.1,16,17.1,16.8,17z"
    />
  </g>
  <g
    id="_x23_e5e5e5ff_00000178916699622732163050000013266214814915520423_"
    className="st0"
  >
    <path
      className="st2"
      d="M0.8,3.5c7.4-0.1,14.9,0,22.3,0c0.5-0.1,1,0.5,1,1c0,5.3,0,10.7,0,16c0.1,0.6-0.6,1.1-1.2,1
		c-7.3,0-14.5,0-21.8,0c-0.5,0-1.1-0.4-1-1c0-5.3,0-10.6,0-16C0.2,4.1,0.5,3.7,0.8,3.5 M3.8,5.5c2.8,2.5,5.6,5.1,8.4,7.6
		c2.8-2.5,5.6-5.1,8.4-7.6C15,5.5,9.4,5.5,3.8,5.5 M2.2,6.8c0,3.8,0,7.5,0,11.3c2-2,4-3.9,6-5.9C6.2,10.4,4.2,8.6,2.2,6.8
		 M16.1,12.2c1.9,2,4,3.8,6,5.8c0-3.7,0-7.5,0-11.2C20.1,8.6,18.1,10.4,16.1,12.2 M3.6,19.4c5.7,0,11.4,0,17.1,0
		c-2-2-4.1-3.9-6.1-5.9c-0.7,0.6-1.3,1.2-1.9,1.7c-0.3,0.3-0.9,0.3-1.2,0c-0.7-0.6-1.3-1.2-1.9-1.7C7.6,15.5,5.6,17.5,3.6,19.4z"
    />
  </g>
  <g
    id="_x23_e5e5e5ff_00000106855317993874251070000007426748790159453357_"
    className="st0"
  >
    <path
      className="st2"
      d="M1.1,1.6C1,1,1.5,0.4,2.1,0.4c5.9,0,11.7,0,17.6,0c0.4,0,0.8,0,1.1,0.3C21.1,1,21,1.6,21.1,2
		c0,3.1,0,6.3,0,9.4c0,0.7-0.8,1.2-1.4,0.9c-0.4-0.2-0.7-0.7-0.6-1.1c0-2.9,0-5.9,0-8.8c-5.3,0-10.6,0-15.9,0c0,6.6,0,13.3,0,19.9
		c3.3,0,6.7,0,10,0c0.7,0,1.2,0.8,0.9,1.4c-0.2,0.4-0.7,0.7-1.1,0.6c-3.6,0-7.2,0-10.8,0c-0.6,0-1-0.6-1-1.2C1.1,16,1.1,8.8,1.1,1.6
		z"
    />
    <path
      className="st2"
      d="M5.9,4.4c2.7-0.1,5.3,0,8,0c0.4,0,0.9,0.2,1.1,0.6c0.3,0.6-0.2,1.4-0.9,1.4c-2.6,0-5.2,0-7.8,0
		c-0.4,0-0.8-0.1-1-0.5C4.9,5.3,5.3,4.5,5.9,4.4z"
    />
    <path
      className="st2"
      d="M5.9,8.4c3.4,0,6.8,0,10.1,0c0.7,0,1.2,0.7,0.9,1.4c-0.2,0.4-0.7,0.7-1.1,0.6c-3.2,0-6.4,0-9.6,0
		c-0.4,0-0.9-0.2-1.1-0.6C4.9,9.2,5.3,8.5,5.9,8.4z"
    />
    <path
      className="st2"
      d="M5.9,12.4c2.8-0.1,5.7,0,8.5,0c0.4,0,1-0.1,1.3,0.3c0.4,0.4,0.4,1,0,1.4c-0.3,0.3-0.7,0.3-1.1,0.3
		c-2.8,0-5.6,0-8.4,0c-0.5,0-0.9-0.2-1.1-0.6C4.9,13.2,5.3,12.5,5.9,12.4z"
    />
    <path
      className="st2"
      d="M19.9,14.4c0.9,0,1.6,0.7,2.4,1c0.6,0.2,1,0.9,0.6,1.5c-0.9,1.8-1.8,3.5-2.6,5.3c-0.2,0.4-0.4,0.9-0.9,1.1
		c-0.6,0.3-1.3,0.7-1.9,1c-0.6,0.3-1.4-0.2-1.4-0.9c0-1-0.2-2,0.3-2.9c0.9-1.8,1.8-3.5,2.7-5.3C19.2,14.8,19.5,14.4,19.9,14.4z"
    />
    <path
      className="st2"
      d="M5.9,16.4c1-0.1,2,0,3,0c0.5,0,0.9,0.2,1.1,0.6c0.3,0.6-0.2,1.4-0.9,1.4c-1.1,0-2.2,0-3.3,0
		C4.9,18.1,4.9,16.5,5.9,16.4z"
    />
  </g>
  <g
    id="_x23_e5e5e5ff_00000121270353157492855610000005839018797187808946_"
    className="st0"
  >
    <path
      className="st2"
      d="M19.6,1.7c0.4-0.3,1-0.1,1.3,0.2c0.9,0.9,1.7,1.7,2.6,2.6c0.3,0.3,0.6,0.6,0.6,1c0,0.4-0.3,0.7-0.6,1
		c-2.2,2.2-4.3,4.3-6.5,6.5c-0.4,0.5-1.1,0.6-1.6,0.8c-1.2,0.4-2.3,0.8-3.5,1.1c-0.7,0.3-1.5-0.5-1.2-1.2c0.4-1.4,0.9-2.8,1.4-4.2
		c0.2-0.6,0.7-1,1.1-1.5c1.8-1.8,3.6-3.6,5.4-5.4C19,2.3,19.3,2,19.6,1.7 M14.2,10c-0.5,0.8-0.6,1.7-0.9,2.5c1-0.4,2.2-0.4,2.9-1.3
		c1.8-1.9,3.7-3.7,5.6-5.6c-0.5-0.5-1-1-1.6-1.6C18.2,6,16.2,8,14.2,10z"
    />
    <path
      className="st2"
      d="M0.2,4.7c-0.1-0.6,0.4-1.2,1-1.2c4.1,0,8.3,0,12.4,0c0.7-0.1,1.3,0.7,1,1.4c-0.2,0.5-0.7,0.6-1.2,0.6
		c-3.7,0-7.5,0-11.2,0c0,5.3,0,10.6,0,15.9c5.3,0,10.6,0,15.9,0c0-2.5,0-4.9,0-7.4c0-0.5,0.4-1.1,1-1.1c0.6,0,1,0.5,1,1.1
		c0,2.6,0,5.2,0,7.8c0,0.4,0.1,1-0.3,1.3c-0.3,0.3-0.9,0.3-1.3,0.3c-5.7,0-11.4,0-17.2,0c-0.6,0-1.2-0.4-1.2-1
		C0.2,16.6,0.2,10.7,0.2,4.7z"
    />
  </g>
  <g
    id="_x23_e5e5e5ff_00000012449601343193455620000003598362685826638725_"
    className="st0"
  >
    <path
      className="st2"
      d="M10.2,0.6c2.7-0.4,5.5,0,7.9,1.4c2.8,1.7,5,4.5,5.7,7.8c0.6,3,0.2,6.4-1.6,9c-1.5,2.4-3.9,4.3-6.6,5.1
		c-3.1,0.9-6.7,0.6-9.5-1.1c-0.4-0.2-0.9-0.5-1-1c-0.2-0.7,0.6-1.4,1.3-1.1c0.5,0.2,1,0.6,1.6,0.8c2.8,1.3,6.3,1.2,9-0.4
		c1.9-1.1,3.4-2.7,4.3-4.7c1-2.4,1.1-5.1,0.2-7.5c-1-2.5-3-4.6-5.5-5.7c-2.7-1.1-5.8-1-8.4,0.3C7,3.8,6.4,4.4,5.7,4.1
		C5.1,3.9,4.8,3,5.4,2.5C6.8,1.5,8.5,0.9,10.2,0.6z"
    />
    <path
      className="st2"
      d="M4.2,7.9C4.5,7.5,5,7.2,5.5,7.5c0.6,0.3,0.7,1.1,0.3,1.6c-0.7,0.8-1.5,1.5-2.3,2.3c3.8,0,7.7,0,11.5,0
		c0.7,0,1.2,0.8,0.9,1.4c-0.2,0.4-0.6,0.7-1.1,0.6c-3.8,0-7.6,0-11.3,0c0.8,0.8,1.5,1.5,2.3,2.3C6.1,16,6.2,16.6,5.9,17
		c-0.3,0.5-1.1,0.5-1.5,0.1c-1.3-1.3-2.6-2.6-3.9-3.9C0,12.8,0,12.1,0.4,11.7C1.6,10.4,2.9,9.2,4.2,7.9z"
    />
  </g>
</svg>

    )
}