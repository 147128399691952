import React from "react";
import ReactDOM from "react-dom";
import { ChakraProvider, ColorModeScript ,color,extendTheme} from "@chakra-ui/react";

import App from "./App.jsx";


// ReactDOM.render(
 

//       <ChakraProvider >
        
//         <App />
//       </ChakraProvider>

// ,
//   document.getElementById("root")
// );


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);