
import { useCallback, useEffect, useRef, useState } from "react";
import { useSocket } from "../../Hooks/useSocket";
import MDEditor from '@uiw/react-md-editor';

// var md = require('markdown-it')();
import "../../index.css"
import Activityicon from "../../components/icon/Activity"
import Institutionicon from "../../components/icon/institution"
import Courses from "../../components/icon/course"
import Calendar from "../../components/icon/Calendar"
import Grades from "../../components/icon/Grades"
import SignOut from "../../components/icon/SignOut"
import Tools from "../../components/icon/Tools"
function Blackbord() {

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const inputRef = useRef();
  const { socket } = useSocket("https://chatsocket.lmlmd.xyz")
  // const { socket } = useSocket("http://localhost:4000/")
  // const { socket } = useSocket("wss://chatsocket.lmlm.online")
  useEffect(() => {

    getMessages();
  }, []);

  const getMessages = useCallback(() => {
    socket.on("server:getMessages", (messages) => {
      setMessages(messages);
    });
  }, []);

  const onEnterPress = (e) => {

    if(e.keyCode == 13 && e.shiftKey == false) {
      console.log(message)
      e.preventDefault();
      chatgpt(e)
    }
  }

  const sendMessage = (e) => {
    e.preventDefault()
    socket.emit("client:addMessage", { message, hour: Date.now() })
    setMessage("")
    inputRef.current.focus();

  };

  const clearMessage = (e) => {
    e.preventDefault()
    socket.emit("client:clear", {})
  };


  const chatgpt = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now() })
    socket.emit("client:chatgpt", { message, hour: Date.now() });
    // message = "";
    setMessage("")

  };


  const itexamanswers = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now() })
    socket.emit("client:itexamanswers", { message, hour: Date.now() });
    // message = "";
    setMessage("")

  };

  const itexamanswersonly = (e) => {
    e.preventDefault()
    console.log(message.length > 1)
    socket.emit("client:addMessage", { message, hour: Date.now() })
    socket.emit("client:itexamanswersonly", { message, hour: Date.now() });
    // message = "";
    setMessage("")

  };

  const customNetwork = (e) => {
    setMessage("Host IP Address	Addr. Class	Network Address	Host Address	Network Broadcast Address	Default Subnet Mask ");
  };





 const expacn = (e) => {
  setMessage("I will give you questions I want the answer without clarification")
 }


 const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}


const handlePaste = async (e) => {
  if (e.clipboardData.files.length) {
    const fileObject = e.clipboardData.files[0];
    const base64 = await convertBase64(fileObject)
    //image.pngsetfiles(base64)
    socket.emit("client:addMessage", { src: base64, hour: Date.now() })

  } else {
    alert(
      "No image data was found in your clipboard. Copy an image first or take a screenshot."
    );
  }
};


  return (
    <>

      <>
        {/* <style
          dangerouslySetInnerHTML={{
            __html:
              '@charset "UTF-8";[ng\\:cloak],[ng-cloak],[data-ng-cloak],[x-ng-cloak],.ng-cloak,.x-ng-cloak,.ng-hide:not(.ng-hide-animate){display:none !important;}ng\\:form{display:block;}.ng-animate-shim{visibility:hidden;}.ng-anchor{position:absolute;}'
          }}
        /> */}
        <link
          href="https://blackboard.uob.edu.bh/favicon.ico"
          rel="icon"
          type="image/x-icon"
        />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimal-ui,user-scalable=yes"
        />
        <base href="//ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb" />
        <title ng-bind="index.title">Courses</title>
        <meta
          name="author"
          bb-translate-attrs="{'content':'global.meta.author'}"
          content="Blackboard"
        />
        <meta
          name="copyright"
          bb-translate-attrs="{'content':'global.meta.copyright'}"
          content="&copy; 1997-2015 Blackboard Inc. All Rights Reserved. U.S. Patent No. 7,493,396 and 7,558,853. Additional Patents Pending."
        />

        <link
          className="initial-css-link"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/71973-9774406a534671fc.css"
          rel="stylesheet"
        />
        <link
          className="initial-css-link"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/app-9774406a534671fc.css"
          rel="stylesheet"
        />




        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/4729-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/50454-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/86054-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/33196-9774406a534671fc.css"
        />
      
        <link
          rel="stylesheet"
          type="text/css"
          href="https://web-sdk.aptrinsic.com/style.css?a=AP-PQQY5YJEHTTA-2"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/22258-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/27459-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/75585-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/42953-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/26158-9774406a534671fc.css"
        />
        <link
          className="ally-styles"
          href="https://prod-eu-central-1.ally.ac//static/integration/learn/ultra.38df02dd9a9a279bc9688933781aa8b0.css"
          id="ally-learn-ultra-styles"
          rel="stylesheet"
        />
        <link
          className="ally-styles"
          href="https://prod-eu-central-1.ally.ac//static/integration/css/ally.3076e844b51dc74dab2c06b84fe86de1.css"
          id="ally-styles"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/98978-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/10170-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/95015-9774406a534671fc.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://ultra.content.blackboardcdn.com/ultra/uiv3900.65.0-rel.20_1f96feb/61654-9774406a534671fc.css"
        />



        <div id="site-wrap" className="off-canvas-wrap" data-offcanvas="">
          <div className="row" style={{ height: 0 }} />

          <div className="inner-wrap" ui-view="">




            <div className="base" >
              <a className="exit-off-canvas" />
              <aside onPaste={handlePaste}
                ng-attr-id="{{ ::(previewMode ? '' : 'side-menu') }}"

                className="hide-in-background themed-background-primary-fill-only left-off-canvas-menu color-selection-live-mode"
                ng-show="::!disableSideMenu"
                tabIndex={-1}
                id="side-menu"
                aria-hidden="false"
              >
                <div className="bb-inner-wrap">


                  <header
                    ng-attr-role="{{ ::(previewMode ? 'presentation' : 'banner')}}"
                    role="banner"
                  >
                    <span className="branding themed-logo-background-primary-fill">
                      <button
                        className="exit-off-canvas menu-toggle standard-button left-off-canvas-toggle dark-background"
                        analytics-id="exit.off.canvas.toggle.button"
                      >

                        <span className="sr-only" bb-translate="">
                          Close
                        </span>
                      </button>
                      <a
                        href="https://blackboard.uob.edu.bh/ultra/course"
                      >
                        <img
                          className="site-logo"
                          alt="University of Bahrain"
                          ng-src="https://blackboard.uob.edu.bh/branding/_1_1/CustomNavigationLogo.png?m=kfh2m4vj"
                          src="https://blackboard.uob.edu.bh/branding/_1_1/CustomNavigationLogo.png?m=kfh2m4vj"
                        />
                      </a>
                    </span>
                  </header>
                  {/*NAVIGATION*/}
                  <nav
                    ng-attr-role="{{ ::(previewMode ? 'presentation' : 'navigation')}}"
                    bb-translate-attrs="{'aria-label': 'base.nav.title'}"
                    role="navigation"
                    aria-label="Main"
                  >
                    <ul className="off-canvas-list" id="base_tools" >


                      <li className="base-navigation-button" onClick={sendMessage}>
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <Institutionicon/>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" >
                              Institution Page
                            </span>

                          </div>
                        </a>

                      </li>







                      <li className="base-navigation-button" onClick={clearMessage}>
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <Activityicon/>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" >
                              Activity Stream
                            </span>
                          </div>
                        </a>

                      </li>



                      <li className="base-navigation-button active" onClick={chatgpt}>
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <Courses/>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" bb-translate="">
                              Courses
                            </span>
                          </div>
                        </a>
                      </li>




                      <li className="base-navigation-button" onClick={customNetwork}>
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <Calendar/>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" >
                              Calendar
                            </span>
                          </div>
                        </a>

                      </li>
                      <li className="base-navigation-button" onClick={expacn}>
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <svg class="MuiSvgIconroot-0-2-67 makeStylesdirectionalIcon-0-2-66 makeStylesstrokeIcon-0-2-65" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 4v16h22V4H1z">
                                </path>
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 4l8.4419 7.6744L12 14l2.5581-2.3256L23 4M23 20c-3.3676-3.5207-5.5754-5.3288-8.4418-8.3256M1 20l8.4419-8.3256">
                                </path>
                              </svg>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" >
                              Messages
                            </span>
                          </div>
                        </a>

                      </li>
                      <li className="base-navigation-button" onClick={itexamanswersonly} name="itexamanswersonly" >
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <Grades/>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" >
                              Grades
                            </span>
                          </div>
                        </a>

                      </li>


                      <li className="base-navigation-button" onClick={itexamanswers} onPaste={handlePaste} >
                        <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active">
                          <div ng-switch-default="">
                            <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                              <Tools/>
                            </bb-ui-icon-with-badge>
                            <span className="link-text" >
                              Tools
                            </span>
                          </div>
                        </a>

                      </li>

                      <div ng-switch="$ctrl.link.type">
                        <li className="base-navigation-button" >
                          <a className="base-navigation-button-content themed-background-primary-alt-fill-only theme-border-left-active" href="https://blackboard.uob.edu.bh/ultra/course">
                            <div ng-switch-default="">
                              <bb-ui-icon-with-badge color="indicatorSecondary" icon="$ctrl.link.iconElement" position="corner-top-right" show-badge="$ctrl.link.showBadge()" badge-content="$ctrl.link.badgeContent()" custom-badge-class="base-navigation-button-badge">
                                <SignOut/>
                              </bb-ui-icon-with-badge>
                              <span className="link-text" bb-translate="">
                                Sign Out
                              </span>
                            </div>
                            <span
                              className="round alert label ng-hide"
                            />
                          </a>

                        </li>
                      </div>


                    </ul>
                  </nav>
                </div>
                <div className="side-menu-footer">
                  <a className="footer-link" >
                    Privacy
                  </a>
                  <a className="footer-link"  >
                    Terms
                  </a>
                </div>
              </aside>
              {/*MAIN CONTENT*/}

              <main
                id="main-content"
                ng-if="::!previewMode"
                className="bb-offcanvas-container"
                bb-lti-inline-launcher=""
              >

                <div
                  ui-view=""
                  id="main-content-inner"
                  className="hide-in-background"
                  tabIndex={-1}
                  aria-hidden="false"
                >
                  <div
                    bb-first-time-container=""
                    bb-cache-compilation="base-courses"
                    className="base-courses-container"
                    bb-offcanvas-pausal-scope=""
                  >
                    <header
                      className="base-courses-header-container base-header themed-background-primary-medium-down color-selection-live-mode"
                      ng-multi-transclude-controller=""
                      ng-attr-aria-hidden="{{ (previewMode ? 'true' : 'false') }}"
                      page-title-key="base.courses.pageTitle"
                      has-filter="true"
                      aria-hidden="false"
                    >

                      <h1
                        className="page-title a themed-background-primary-medium-down has-filter js-base-page-skip-link-target"
                        id="main-heading"
                        bb-translate=""
                        tabIndex={-1}
                      >
                        Courses
                      </h1>
                      <div
                        ng-multi-transclude="base-header-tools"
                        className="base-header-tools-transclude"
                      >
                        <div name="base-header-tools" className="base-header-tools">

                          <div
                            className="base-header-select"
                            ng-if="!baseCourses.isLargeBreakpoint() && !baseCourses.showMagicMoment"
                          >
                            <div
                              className="filter-wrapper"
                              bb-translate=""

                            >
                              Filter
                            </div>
                            <div className="select-wrapper">
                              <div bb-foundation-defer="">
                                <button
                                  data-dropdown="courses-filter"
                                  bb-click-focus=".item-selected > a"
                                  bb-translate-attrs="{'aria-label': 'base.courses.coursesFilter'}"

                                  aria-controls="courses-filter"
                                  aria-haspopup="true"
                                  analytics-id="base.courses.filter.button"
                                  aria-label="Courses Filter"
                                >
                                  <div className="select-value">
                                    <span
                                      id="filter-courses-value"

                                      bb-translate-attrs="{'aria-label': 'base.courses.currentView'}"
                                      aria-label="Current View"
                                    >
                                      All Courses
                                    </span>
                                  </div>
                                </button>
                                <div>


                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </header>

                    <div
                      className="base-courses"

                      bb-intersection-observer=""
                      style={{}}
                    >
                      <div
                        className="js-course-carousel"
                      >
                        <div className="container mt-5" >

                          <div className="col-6">
                            <form onSubmit={onEnterPress} style={{ backgroundColor: "" }}>
                              <div className="mb-3">

                                <textarea
                                  id="prompt-textarea"
                                  ref={inputRef}
                                  tabindex="0"
                                  style={{ color: "black", width: "100%", height: "30px" }}
                                  type="text" name="message" value={message} onChange={(e) => setMessage(e.target.value)}

                                />

                              </div>
                              {/* <button className="btn btn-warning mb-3" type="submit">
                                subm
                              </button>
                              <button className="btn btn-warning mb-3" type="button" onClick={clearMessage}>
                                clear
                              </button>

                              <button className="btn  mb-3" type="button" onClick={chatgpt}>
                                g
                              </button> */}
                            </form>
                          </div>

                          <div className="col-12">
                            <ul className="list-groupl">
                              {
                                messages.map((message, i) => (
                                  <li key={i} className="list-group-item">
                                    {/* <Newine displaytext={message.message || ""} /> */}

                                    <div dangerouslySetInnerHTML={{ __html: message.message}} />


                                    <img src={message.src || ""} ></img>
                                  </li>
                                ))

                                
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>

          </div>
        </div>
      </>
    </>
  )
}

export default Blackbord
