import moment from "moment/moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSocket } from "../../Hooks/useSocket";
import { Textarea } from '@chakra-ui/react'
import MDEditor from '@uiw/react-md-editor';
import { Upload } from "@progress/kendo-react-upload";
import { guid } from "@progress/kendo-react-common";
// var md = require('markdown-it')();

import { IKImage, IKVideo, IKContext, IKUpload } from 'imagekitio-react' 
function App() {

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState("")
  const [files, setfiles] = useState([])
  const inputRef = useRef();
  const { socket } = useSocket("wss://chatsocket.lmlm.online")

  const allmassages = messages.join("")

  useEffect(() => {

    getMessages();
  }, []);

  const getMessages = useCallback(() => {
    socket.on("server:getMessages", (messages) => {
      setMessages(messages);
    });

    socket.on("server:getimg", (messages) => {
      console.log(messages);
      setMessages(messages);
    });
  }, []);

  const sendMessage = (e) => {
    e.preventDefault()
    socket.emit("client:addMessage", { message, hour: Date.now() })
    setMessage("")
    inputRef.current.focus();

  };


  const clearMessage = (e) => {
    e.preventDefault()
    socket.emit("client:clear", {})
  };


  const chatgpt = (e) => {
    e.preventDefault()
    socket.emit("client:addMessage", { message, hour: Date.now() })
    socket.emit("client:chatgpt", { message, hour: Date.now() });
    // message = "";
    setMessage("")

  };

  const customNetwork = (e) => {
    setMessage("Host IP Address	Addr. Class	Network Address	Host Address	Network Broadcast Address	Default Subnet Mask ");
  };





  const expacn = (e) => {
    setMessage("I will give you questions I want the answer without clarification")
  }



  function Newine({ displaytext }) {
    // var result = md.render(displaytext);
    return (<>

      <div class="message right-side "  >

        {displaytext.replaceAll('$').replaceAll('\n', '$').split("$").map((i, key) => {
          return <p key={key}>{i}</p>;
        })}

      </div>


    </>)
  }




  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }


  const handlePaste = async (e) => {
    if (e.clipboardData.files.length) {
      const fileObject = e.clipboardData.files[0];
      const base64 = await convertBase64(fileObject)
      setfiles(base64)
      socket.emit("client:addMessage", { src: base64, hour: Date.now() })

    } else {
      alert(
        "No image data was found in your clipboard. Copy an image first or take a screenshot."
      );
    }
  };

  return (
    <div className="container mt-5" >

      <div className="col-6">
        <form onSubmit={sendMessage}>
          <div className="mb-3">

            <textarea

              id="message"
              ref={inputRef}
              style={{ color: "#5665ff", width: "100%", height: "30px" }}
              type="text" name="message" value={message} onChange={(e) => setMessage(e.target.value)}
            />

            {/* <MDEditor value={message} onChange={setMessage} />
            <MDEditor.Markdown source={allmassages} /> */}


          </div>
          <button className="btn btn-warning mb-3" type="submit">
            subm
          </button>
          <button className="btn btn-warning mb-3" type="button" onClick={clearMessage}>
            clear
          </button>

          <button className="btn btn-warning mb-3" type="button" onClick={chatgpt}>
            g
          </button>
          <button className="btn btn-warning mb-3" type="button" onClick={customNetwork}>
            customNetwork
          </button>

          <button className="btn btn-warning mb-3" type="button" onClick={expacn}>
            expacn
          </button>

        </form>
      </div>

      <div className="col-12" onPaste={handlePaste}>
        {
          messages.map((message, i) => (
            <li key={i} className="list-group-item">
              {/* <Newine displaytext={message.message || ""} /> */}

              <div dangerouslySetInnerHTML={{ __html: message.message }} />


              <img src={message.src || ""} ></img>
            </li>
          ))

        }
      </div>





    </div>
  )
}

export default App
